.verified_data {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 3rem;
}

.data_container {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 0.96rem;
}

.data_text_container {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 2%;
}

.data_head {
	color: #000;
	font-family: "Sora", sans-serif;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.data_input {
	color: #000;
	font-family: "Sora", sans-serif;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.certificate_heading_back {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	color: black;
	font-size: x-large;
}

.certificate_heading_back:hover {
	text-decoration: underline;
	cursor: pointer;
}

@media (min-width: 1000px) {
	.verified_data {
		gap: 6rem;
	}

	.data_container {
		gap: 2rem;
	}

	.data_head {
		font-size: 1.25rem;
	}

	.data_input {
		font-size: 1.25rem;
	}
}
