.categories-container {
	text-align: center;
	padding: 20px;
	font-family: "Arial", sans-serif;
  }
  
  .categories-title {
	font-size: 50px;
	font-weight: bold;
	color: #333;
	margin-bottom: 20px;
  }
  
  .categories-list {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 15px;
	margin-bottom: 30px;
  }
  
  .category-button {
	padding: 10px 15px;
	font-size: 16px;
	border: none;
	border-radius: 25px;
	background-color: #ffffff;
	color: #555;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
	cursor: pointer;
	transition: all 0.3s ease;
  }
  
  .category-button:hover {
	background-color: #f0f0f0;
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  }
  
  .category-button.active {
	background-color: #9095A0;
	color: #ffffff;
	box-shadow: 0px 4px 8px #7b8089;
	font-weight: bold;
  }
  
  /* .explore-more-button {
	padding: 12px 20px;
	font-size: 18px;
	border: none;
	border-radius: 25px;
	background-color: #800000;
	color: #ffffff;
	cursor: pointer;
	transition: all 0.3s ease;
  }
  
  .explore-more-button:hover {
	background-color: #a00000;
	box-shadow: 0px 5px 15px rgba(128, 0, 0, 0.3);
  } */
  
  /* Responsive Adjustments */
  @media (max-width: 768px) { /* Mobile devices */
	.categories-title {
	  font-size: 36px; /* Reduced size for mobile */
	  margin-bottom: 15px;
	}
  
	.categories-list {
	  display: flex;
	  flex-wrap: wrap;
	  gap: 10px; /* Space between buttons */
	  justify-content: center;
	}
  
	.category-button {
	  padding: 8px 12px; /* Reduced padding */
	  font-size: 14px; /* Reduced font size */
	  border-radius: 20px; /* Slightly smaller buttons */
	}
  
	/* .explore-more-button {
	  font-size: 16px; 
	  border-radius: 20px;
	} */
  }
  
  /* Tablet adjustments */
  @media (max-width: 1024px) {
	.categories-title {
	  font-size: 42px;
	  margin-bottom: 18px;
	}
  
	.categories-list {
	  gap: 12px;
	}
  
	.category-button {
	  padding: 9px 14px;
	  font-size: 15px;
	}
  
	/* .explore-more-button {
	  padding: 11px 19px;
	  font-size: 17px;
	} */
  }
  