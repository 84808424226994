
.new-password-container {
    width: 100%;
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #000000;
}

form {
    display: flex;
    flex-direction: column;
}

input[type="password"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 14px;
}

button[type="submit"] {
    background-color: #000000;
    color: #ffffff;
    padding: 10px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
    background-color: #000000;
}
.error-message {
    color: red;
    font-size: 14px;
    margin-top: -10px;
    margin-bottom: 10px;
}

/* Responsive Design */
@media screen and (max-width: 767px) {
    .new-password-container {
        width: 100vw;
        /* margin: 20px auto;
        padding: 15px; */
    }

    h2 {
        font-size: 20px;
    }

    input[type="password"],
    button[type="submit"] {
        font-size: 14px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .new-password-container {
        width: 500px;
    }
}

@media screen and (min-width: 1025px) {
    .new-password-container {
        width: 400px;
    }
}