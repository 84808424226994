.course_container {
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 1rem;
	margin-bottom: 2rem;
}

.course_section {
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin-bottom: 3%;
}

.course_section_heading {
	color: #0f0705;
	font-family: "Sora", sans-serif;
	font-size: 1.25rem;
	font-style: normal;
	font-weight: 680;
	line-height: normal;
}

.all_course_cards_container {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	gap: 1rem;
	overflow-x: auto;
	padding: 1rem 0;
	flex-wrap: nowrap;
	-webkit-overflow-scrolling: touch;
}

.course_card {
	position: relative;
	min-width: calc(46% - 0.5rem); /* Prevent cards from shrinking smaller */
	border-radius: 0.75rem;
	border: 1px solid rgba(16, 16, 16, 0.24);
	background: #fff;
	padding: 0.38rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 0.4rem;
}

.course_card_img {
	width: 100%;
	height: 10.875rem;
	align-self: stretch;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	border-radius: 0.5rem;
}

.course_card_img img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.course_card_name {
	color: #000;
	font-family: "Sora", sans-serif;
	font-size: 0.9375rem;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;
}

.course_card_author {
	color: #000;
	font-family: "Sora", sans-serif;
	font-size: 0.625rem;
	font-style: normal;
	font-weight: 300;
	line-height: normal;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;
}

.course_card_footer {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.course_card_prices_container {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 1rem;
}

.course_card_rating {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 0.25rem;
	background: #fca253;
	padding: 0.2rem;

	color: #fff;
	font-family: Sora;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 400;
	line-height: normal;

	position: absolute;
	top: 5%;
	right: 5%;
}

.course_card_price {
	color: rgba(16, 16, 16, 0.85);
	font-family: "Sora", sans-serif;
	font-size: 1rem;
	font-style: normal;
	font-weight: 600;
	line-height: normal;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.course_card_prevPrice {
	position: relative;
	color: rgba(16, 16, 16, 0.37);
	font-family: "Sora", sans-serif;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 400;
	line-height: normal;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.course_card-prevPrice-stroke {
	position: absolute;
	left: 0;
	top: 47%;
	width: 100%;
	height: 1px;
	background-color: rgba(0, 0, 0, 0.65);
	transform: translateY(-50%);
}

/* Medium devices (tablets) */
@media (min-width: 600px) {
	.course_card {
		min-width: calc(31.333% - 0.667rem);
	}

	.course_card_name {
		font-size: 1.3375rem;
	}

	.course_card_author {
		font-size: 0.925rem;
	}
}

/* Large devices (desktops) */
@media (min-width: 1100px) {
	.course_card {
		min-width: calc(23% - 0.75rem);
	}

	.course_card_img {
		height: 16.1875rem;
	}

	.course_card_name {
		font-size: 1.5375rem;
	}

	.course_card_author {
		font-size: 1.225rem;
	}

	.course_card_description {
		font-size: 0.875rem;
		line-height: 1.5rem;
	}

	.course_card_rating {
		position: relative;
		top: auto;
		right: auto;
	}
}

/* Scrollbar styling */
.all_course_cards_container::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}

.all_course_cards_container::-webkit-scrollbar-track {
	background: transparent;
}

.all_course_cards_container::-webkit-scrollbar-thumb {
	background-color: #888888;
	border-radius: 3px;
}

.all_course_cards_container::-webkit-scrollbar-thumb:hover {
	background-color: #666666;
}

.course_card_name,
.course_card_author {
}
