/* body {
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    background-color: #f4f4f9;
} */
.signup-container {
    margin-top:50px;
    margin-bottom:50px;
    width: 100%;
    max-width: 400px;
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.signup-container h2 {
    margin-bottom: 20px;
    text-align: center;
    color: #333;
}
.signup-container form {
    display: flex;
    flex-direction: column;
}
.signup-container input {
    margin-bottom: 15px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
}
.signup-container select {
    margin-bottom: 15px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
}
.signup-container button {
    padding: 10px;
    font-size: 16px;
    color: #fff;
    background-color: #000408;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
/* .signup-container button:hover {
    background-color: #0056b3;
} */
.signup-container .already-account {
    text-align: center;
    margin-top: 15px;
}
.signup-container .already-account a {
    color: #000000;
    text-decoration: none;
}
.signup-container .already-account a:hover {
    text-decoration: underline;
}
.signup-container input.invalid {
    border: 1px solid red;
    background-color: #ffe6e6;
}
.error-message {
    color: red;
    font-size: 14px;
    margin-top: -10px;
    margin-bottom: 10px;
}