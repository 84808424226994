/* body {
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    background-color: #f4f4f9;
} */
.login-container {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    max-width: 400px;
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.login-container h2 {
    margin-bottom: 20px;
    text-align: center;
    color: #333;
}
.login-container form {
    display: flex;
    flex-direction: column;
}
.login-container input {
    margin-bottom: 15px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
}
.login-container button {
    padding: 10px;
    font-size: 16px;
    color: #fff;
    background-color: #000000;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
/* .login-container button:hover {
    background-color: #0056b3;
} */
.login-container .forgot-password {
    text-align: center;
    margin-top: 10px;
}
.login-container .forgot-password a {
    color: #000000;
    text-decoration: none;
}
.login-container .forgot-password a:hover {
    text-decoration: underline;
}
.login-container .sign-up {
    text-align: center;
    margin-top: 15px;
}
.login-container .sign-up a {
    color: #000000;
    text-decoration: none;
}
.login-container .sign-up a:hover {
    text-decoration: underline;
}