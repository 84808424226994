.hero-section {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10.56rem;

	padding: 4.56rem 0px 4.56rem 0px;
}

.hero-section-left {
	position: relative;
	width: 50%;
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	align-items: start;
}

.hero-mobile-categories {
	display: none;
}

.hero-heading {
	font-family: "Afacad", sans-serif;
	font-size: 3rem;
	font-weight: 700;
	line-height: 52.8px;
	text-align: left;
}

.red-text {
	color: #882a2a;
}

.blue-text {
	color: #5f84af;
}

.hero-description {
	font-family: "DM Sans", sans-serif;
	font-size: 20px;
	font-weight: 500;
	line-height: 32.8px;
	text-align: left;
}

.hero-button {
	display: inline-flex;
	padding: 1.5rem;
	justify-content: center;
	align-items: center;
	gap: 1.25rem;
	border-radius: 0.75rem;
	border: 0.819px solid #0f0705;
	background: #882a2a;

	color: #fff;
	font-family: "Sora", sans-serif;
	font-size: 1.5rem;
	font-style: normal;
	font-weight: 520;
	line-height: 100%; /* 1.5rem */
}

.bag-icon {
	width: 2rem;
	height: 2rem;
}

.hero-button:hover {
	cursor: pointer;
}

.hero-section-right {
	position: relative;
	width: 50%;
}

.hero-image {
	width: 100%;
}

.hm-category-card:hover {
	cursor: pointer;
}

/* for medium sized devices  */
@media (max-width: 1100px) {
	.hero-section {
		gap: 2.56rem;
	}

	.hero-mobile-categories {
		position: absolute;
		width: 200%;
		top: -33%;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.hm-category-card {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 0.1rem;
	}

	.hmc-image-men {
		width: 2.8125rem;
		height: 3rem;
	}
	.hmc-image-women {
		width: 1.875rem;
		height: 3rem;
		flex-shrink: 0;
	}
	.hmc-image-kid {
		width: 2.5rem;
		height: 3rem;
		flex-shrink: 0;
	}
	.hmc-image-electornics {
		width: 3.1875rem;
		height: 3rem;
		flex-shrink: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.hmc-image-onlineLearning {
		width: 2.8125rem;
		height: 3rem;
		flex-shrink: 0;
	}
	.hmc-image-banking {
		width: 2.8125rem;
		height: 3rem;
		flex-shrink: 0;
	}

	.hmc-image-men img {
		width: 100%;
		height: 100%;
	}
	.hmc-image-women img {
		width: 100%;
		height: 100%;
	}
	.hmc-image-kid img {
		width: 100%;
		height: 100%;
	}
	.hmc-image-electornics img {
		width: 100%;
		height: 80%;
	}
	.hmc-image-onlineLearning img {
		width: 100%;
		height: 100%;
	}
	.hmc-image-banking img {
		width: 100%;
		height: 100%;
	}

	.hmc-text {
		color: #5f84af;
		font-family: "DM Sans", sans-serif;
		font-size: 0.625rem;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}
}

@media (max-width: 1000px) {
	.hero-mobile-categories {
		top: -25%;
	}
}

/* for mobile phones  */
@media (max-width: 900px) {
	.hero-section {
		flex-direction: column;
		justify-content: center;
		align-items: center;

		padding: 0.56rem 0px 1.56rem 0px;
	}

	.hero-section-left {
		width: 100%;
		justify-content: center;
		align-items: center;
		gap: 1.38rem;
	}

	.hero-mobile-categories {
		position: relative;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.hm-category-card {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 0.1rem;
	}

	.hmc-image-men {
		width: 2.8125rem;
		height: 3rem;
	}
	.hmc-image-women {
		width: 1.875rem;
		height: 3rem;
		flex-shrink: 0;
	}
	.hmc-image-kid {
		width: 2.5rem;
		height: 3rem;
		flex-shrink: 0;
	}
	.hmc-image-electornics {
		width: 3.1875rem;
		height: 3rem;
		flex-shrink: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.hmc-image-onlineLearning {
		width: 2.8125rem;
		height: 3rem;
		flex-shrink: 0;
	}
	.hmc-image-banking {
		width: 2.8125rem;
		height: 3rem;
		flex-shrink: 0;
	}

	.hmc-image-men img {
		width: 100%;
		height: 100%;
	}
	.hmc-image-women img {
		width: 100%;
		height: 100%;
	}
	.hmc-image-kid img {
		width: 100%;
		height: 100%;
	}
	.hmc-image-electornics img {
		width: 100%;
		height: 80%;
	}
	.hmc-image-onlineLearning img {
		width: 100%;
		height: 100%;
	}
	.hmc-image-banking img {
		width: 100%;
		height: 100%;
	}

	.hmc-text {
		color: #5f84af;
		font-family: "DM Sans", sans-serif;
		font-size: 0.625rem;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}

	.hero-heading {
		width: 80%;
		color: #101010;
		text-align: center;
		font-family: "Afacad", sans-serif;
		font-size: 2.125rem;
		font-style: normal;
		font-weight: 700;
		line-height: 110%; /* 2.3375rem */
	}

	.hero-description {
		width: 80%;
		color: rgba(1, 1, 1, 0.85);
		text-align: center;
		font-family: "DM Sans";
		font-size: 0.9375rem;
		font-style: normal;
		font-weight: 400;
		line-height: 164%; /* 1.5375rem */
	}

	.hero-button {
		display: flex;
		width: 6.25rem;
		height: 2.4375rem;
		padding: 0rem 0.325rem;
		gap: 0.5rem;
		justify-content: center;
		align-items: center;
		flex-shrink: 0;
		border-radius: 0.3125rem;
		border: 0.819px solid #0f0705;

		color: #fff;
		font-family: "Sora", sans-serif;
		font-size: 0.75rem;
		font-style: normal;
		font-weight: 520;
		line-height: 100%; /* 0.75rem */
	}

	.bag-icon {
		width: 1.5rem;
		height: 1.5rem;
		flex-shrink: 0;
	}

	.hero-section-right {
		display: none;
	}
}
