.contact-main {
    max-width: 75rem;
    margin: 0 auto;
    padding: 1.25rem;
}

.contact-wrapper {
    display: flex;
    gap: 2.5rem;
    margin-top: 1.25rem;
}

.contact-info-section {
    flex: 1;
    margin-top: 2rem;
}

.contact-title {
    font-size: 1.5rem;
    margin-bottom: 1.25rem;
    font-weight: 600;
}

.contact-description,
.contact-social,
.contact-email,
.contact-thank-you {
    margin-bottom: 1rem;
    line-height: 1.6;
    font-size: 1rem;
}

.contact-form-section {
    flex: 1;
    background-color: #f8f8f8;
    padding: 2rem;
    border-radius: 0.5rem;
}

.contact-form-title {
    font-size: 1.25rem;
    margin-bottom: 0.625rem;
    font-weight: 600;
}

.contact-form-subtitle {
    color: #666;
    margin-bottom: 1.25rem;
    font-size: 1rem;
}

/* Fix for name group layout */
.contact-name-group {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
    width: 100%;
}

.contact-input-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
}

.contact-name-group .contact-input-container {
    flex: 1;
}

.contact-input-group {
    margin-bottom: 1rem;
    position: relative;
    width: 100%;
}

.contact-input,
.contact-textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 0.25rem;
    font-size: 0.875rem;
    box-sizing: border-box;
}

.contact-textarea {
    min-height: 7.5rem;
    resize: vertical;
}

/* Fix for phone group layout */
.contact-phone-group {
    display: flex;
    align-items: stretch;
    width: 100%;
}

.contact-country-code {
    background-color: #fff;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-right: none;
    border-radius: 0.25rem 0 0 0.25rem;
    display: flex;
    align-items: center;
}

.contact-phone-group .contact-input-container {
    flex: 1;
}

.contact-phone-input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.contact-char-count {
    position: absolute;
    bottom: 0.625rem;
    right: 0.625rem;
    font-size: 0.75rem;
    color: #666;
}

.contact-submit-btn {
    width: 100%;
    padding: 0.75rem;
    background-color: #000;
    color: white;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.2s ease;
}

.contact-submit-btn:hover {
    background-color: #333;
}

/* Error styling */
.contact-input-error {
    border: 1px solid #ff3333 !important;
    background-color: #fff8f8;
}
  
.contact-error-message {
    color: #ff3333;
    font-size: 0.8rem;
    margin-top: 3px;
    display: block;
}
  
.contact-char-count-error {
    color: #ff3333;
}
.country-code-error-size-fix
{
    height: 2.65rem;
}

/* Responsive Design */
@media screen and (max-width: 48rem) {
    .contact-wrapper {
        flex-direction: column;
        gap: 1.875rem;
    }

    .contact-name-group {
        flex-direction: column;
        gap: 1rem;
    }

    .contact-name-group .contact-input-container {
        width: 100%;
    }

    .contact-form-section {
        padding: 1.25rem;
    }
}

/* Additional responsive breakpoint for smaller screens */
@media screen and (max-width: 30rem) {
    .contact-main {
        padding: 1rem;
    }

    .contact-form-section {
        padding: 1rem;
        box-shadow: 2px 4px 8px gray;
    }

    .contact-title {
        font-size: 1.25rem;
    }

    .contact-form-title {
        font-size: 1.125rem;
    }
}