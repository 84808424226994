body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f9f9f9;
    color: #333;
  }
  
  .cart-category {

    margin:10px auto;
    padding: 20px;
    /* max-width: 1200px; */
    width:90%;
  }
  
  .category-heading {
    font-size: 28px;
    color: #333;
    margin-bottom: 16px;
    text-transform: capitalize;
  }
  
  .cart-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 46px;
  }
  
  .cart {
    background: #fff;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .cart:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  }

.cart-image {
    width: 100%;
    height: 180px;
    object-fit:fill;
  }
  
  .cart-content {
    padding: 16px;
  }
  
  .cart-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }
  
  .cart-duration {
    padding-left: 10px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    padding: 4px 8px;
    border-radius: 4px;
  }
  .cart-image-container {
    position: relative;
    width: 100%;
    height: 180px;
    overflow: hidden;
  }
  
  .cart-title {
    padding:0px;
    font-size: 18px;
    font-weight: 600;
    margin: 8px 0;
    color: #222;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  
  .cart-description {
    padding:0px;
    font-size: 14px;
    color: #555;
    margin-bottom: 16px;
  }
  .read-more {
    color: #ff0c07;
    text-decoration: none;
    font-weight: 500;
    cursor: pointer;
  }
  
  .read-more:hover {
    text-decoration: underline;
  }
  .cart-footer {
    padding-left: 0px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .author-image {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .author-name {
    font-size: 14px;
    color: #333;
    font-weight: 500;
  }
  
  @media (max-width: 600px) {
    .cart {
      grid-template-columns: 2fr;
    }
  }
  @media (max-width: 300px) {
    .cart-container {
      grid-template-columns: 1fr;
      gap: 12px; 
    }
  
    .cart {
      margin-left: 0 auto; 
      width: calc(100% - 20px); 
      box-sizing: border-box; 
    }
  }
  