.certificate_container {
	width: 90%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.certificate_heading {
	width: 120%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: black;
	align-items: center;
	flex-shrink: 0;
	margin-left: -3%;
}

.certificate_heading_text {
	width: 70%;
	color: #fff;
	text-align: center;
	font-family: "Sora", sans-serif;
	font-size: 1.25rem;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: 0.05rem;
	padding: 0.5rem;
}

.certificate_hero {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 10% 10% 15% 10%;
}

.certificate_left {
	width: 90%;
	padding: 2.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #f9f9f9;
	box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.2);
}

.certificate_form {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	gap: 1.56rem;
}

.cf_header {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 0.6rem;
}

.cf_heading {
	color: #0f0705;
	font-family: "Sora", sans-serif;
	font-size: 1.375rem;
	font-style: normal;
	font-weight: 680;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 2%;
}

.cf_header_text {
	color: rgba(16, 16, 16, 0.6);
	font-family: "Sora", sans-serif;
	font-size: 0.625rem;
	font-style: normal;
	font-weight: 400;
}

.cf_inputs {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 1rem;
}

.labels_group {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 0.94rem;
}

.cf_label {
	color: #000;
	font-family: "Sora", sans-serif;
	font-size: 0.9375rem;
	font-style: normal;
	font-weight: 600;
}

.certificate_input {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;

	border-radius: 0.2125rem;
	border: 1px solid rgba(16, 16, 16, 0.5);
	background: #fff;
}

.certificate_input input[type="text"] {
	width: 100%;
	color: #a9a7a2;
	font-family: "Sora", sans-serif;
	font-size: 0.625rem;
	font-style: normal;
	font-weight: 400;
	border: none;
	padding: 2%;
}

.cf_button {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1%;

	border-radius: 0.3125rem;
	border: 0.819px solid #0f0705;
	background: #0f0705;

	color: #fff;
	font-family: "Sora", sans-serif;
	font-size: 1.125rem;
	font-style: normal;
	font-weight: 520;
}

.cf_button:hover {
	cursor: pointer;
}

.certificate_right {
	display: none;
}

@media (min-width: 1000px) {
	.certificate_left {
		width: 50%;
	}

	.certificate_heading_text {
		width: 80%;
		font-size: 2.25rem;
		font-weight: 600;
		padding: 0.9rem;
	}

	.certificate_form {
		gap: 8rem;
	}

	.cf_heading {
		font-size: 2rem;
		font-weight: 680;
	}

	.cf_header_text {
		font-size: 1.5rem;
		font-weight: 400;
	}

	.labels_group {
		gap: 1.25rem;
	}

	.cf_label {
		font-size: 1.25rem;
		font-weight: 600;
	}

	.certificate_input input[type="text"] {
		font-size: 1rem;
		padding: 2%;
	}

	.cf_button {
		font-size: 1.5rem;
	}

	.certificate_right {
		width: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
