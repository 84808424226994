.card_wrapper {
    list-style-type: none;
    padding-left: 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.7rem;
    overflow-x: auto;
    flex-wrap: nowrap;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    margin-bottom: 1rem;
}

.card_wrapper::-webkit-scrollbar {
    display: none; 
}

.card_wrapper {
    -ms-overflow-style: none; 
    scrollbar-width: none; 
}

.card_container {
    flex: 0 0 auto;
    width: 165px;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.image-container {
    position: relative;
    height: auto;
}

.card_image {
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    height: 100px;
    object-fit: cover;
}

.time-badge-image {
    position: absolute;
    bottom: 6px;
    left: 3px;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 3px 6px;
    border-radius: 4px;
    font-size: 12px;
}

.time-badge-imag-desktop {
    display: none;
}

.card_title {
    margin: 5px 8px;
    font-size: 16px;
    color: #171a1f;
    font-weight: 600;
}

.card_description_container {
    height: auto;
    margin-left: 8px;
    margin-right: 8px;
}

.card_description-desktop {
    display: none;
}

.card_description {
    color: #171a1fbf;
    font-size: 12px;
    margin: 0;
    width: 100%;
    line-height: 1.4em;
    text-overflow: ellipsis;
    display: -webkit-box;
    overflow: hidden;
    height: auto;
    line-height: 1.4em;
}

.author_container {
    display: flex;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 10px;
    margin-left: 8px;
}

.author_image {
    height: 25px;
    width: 25px;
    border-radius: 50px;
}

.written_by {
    font-size: 10px;
    margin: 0;
    color: #171a1fbf;
}

.author_name {
    font-size: 10px;
    margin: 0;
    font-weight: 600;
}

.writter_container {
    margin-left: 5px;
}

.card_wrapper-desktop {
    display: none;
}

@media screen and (min-width: 900px) {
    .card_wrapper {
        display: none;
    }

    .card_wrapper-desktop {
        list-style-type: none;
        padding-left: 0px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 2rem; 
        overflow-x: auto;
        flex-wrap: nowrap;
        width: 100%;
        -webkit-overflow-scrolling: touch;
        margin-bottom: 1rem;
        position: relative;
    }

    .card_wrapper-desktop::-webkit-scrollbar {
        display: none;
    }

    .card_wrapper-desktop {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }


    .card_container-desktop {
        flex: none;
        width: 410px;
        border-radius: 2px;
        transition: transform 0.1s ease, box-shadow 0.1s ease;
        cursor: pointer;
        background-color: #fff;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        padding: 12px; 
    }

    .card_container-desktop:hover {
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2) !important;
        transform: translateY(-3px) !important;
    }

    .image-container-desktop {
        width: 100%;
    }

    .card_image-desktop {
        width: 100%;
        height: 180px; /* Increased height for better visuals */
        border-radius: 8px;
        object-fit: cover;
    }

    .time-badge-image {
        display: none;
    }

    .time-badge-imag-desktop {
        display: block;
        font-size: 14px;
        font-weight: 600;
        position: relative;
        bottom: 0px;
        left: 0px;
        padding: 0px 0px;
        background-color: transparent;
        color: #171a1fbf;
    }

    .card_title {
        font-size: 1.2rem; /* Increased font size for better readability */
        line-height: 1.4rem;
        margin-bottom: 8px;
    }

    .card_description {
        display: none;
    }

    .card_description-desktop {
        color: #171a1fbf;
        font-size: 14px; /* Slightly increased font size */
        line-height: 1.6em;
        margin-top: 8px;
        margin-bottom: 8px;
        width: 100%;
        text-overflow: ellipsis;
        display: -webkit-box;
        overflow: hidden;
        height: auto;
    }

    .written_by {
        font-size: 0.7rem;
    }

    .author_name {
        font-size: 0.8rem;
        font-weight: 700;
    }

    .author_image {
        height: 30px;
        width: 30px;
    }

    .writter_container {
        margin-left: 6px;
    }

    .time-badge-imag-desktop {
        font-size: 12px;
    }

    .author_container-desktop {
        display: flex;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}
