.about-main {
    max-width: 75rem;
    margin: 0 auto;
    padding: 2rem 1.25rem;
}

.about-title {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 2rem;
    text-align: center;
}

.about-container {
    display: flex;
    gap: 1.5rem;
}

.about-left-column {
    flex: 1.2;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.about-right-column {
    flex: 0.8;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.about-card {
    background-color: #f8f8f8;
    padding: 1.5rem;
    border-radius: 0.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.about-section-title {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: #000;
}

.about-description {
    font-size: 1rem;
    line-height: 1.6;
    color: #333;
}

.about-offer-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.about-offer-list li {
    position: relative;
    padding-left: 1rem;
    margin-bottom: 0.75rem;
    line-height: 1.5;
}

.about-offer-list li::before {
    content: "•";
    position: absolute;
    left: 0;
    color: #000;
}

/* Responsive Design */
@media screen and (max-width: 48rem) {
    .about-container {
        flex-direction: column;
    }

    .about-left-column,
    .about-right-column {
        flex: 1;
    }

    .about-main {
        padding: 1rem;
    }

    .about-title {
        font-size: 1.75rem;
        margin-bottom: 1.5rem;
        text-align: left;
    }

    .about-card {
        padding: 1.25rem;
    }
}

/* Additional responsive breakpoint for smaller screens */
@media screen and (max-width: 30rem) {
    .about-main {
        padding: 0.75rem;
    }

    .about-title {
        font-size: 1.5rem;
        margin-bottom: 1rem;
        text-align: center;
    }

    .about-card {
        padding: 1rem;
    }

    .about-section-title {
        font-size: 1.125rem;
        margin-bottom: 0.75rem;
    }

    .about-description,
    .about-offer-list li {
        font-size: 0.875rem;
    }

    .about-container {
        gap: 1rem;
    }

    .about-left-column,
    .about-right-column {
        gap: 1rem;
    }
}