.advertisement-container {
	position: relative;
	width: 100%;
	height: 40vh;
	flex-shrink: 0;
	border-radius: 12px;
	background: lightgray;
	display: flex;
	overflow: hidden;
}

.advertisement-card {
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 12px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.advertisement-image1 {
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.advertisement-image1 img {
	width: 100%;
	height: auto;
	object-fit: cover;
	border-radius: 12px;
}

.advertisement-image1::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	border-radius: 12px;
	z-index: 1;
}

.advertisement-image1-textbox {
	position: absolute;
	top: 20%;
	left: 10%;
	z-index: 10;
	width: auto;
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.advertisement-image1-text1 {
	color: #fff;
	font-family: "DM Sans", sans-serif;
	font-size: 2rem;
	font-weight: 800;
	line-height: 1.2;
}

.advertisement-image1-subtext {
	color: #d6782b;
	font-family: "DM Sans", sans-serif;
	font-size: 2.5rem;
	font-weight: 800;
	line-height: 1.2;
}

.advertisement-image1-text2 {
	color: #fff;
	font-family: "DM Sans", sans-serif;
	font-size: 1.5rem;
	font-weight: 500;
	line-height: 1.2;
}

.advertisement-image2 img {
	position: absolute;
	right: 0;
	top: 0;
	width: 40%;
	height: auto;
	flex-shrink: 0;
	border-radius: 0px 12px 12px 0px;
	background: lightgray 50% / cover no-repeat;
}

/* Medium screens */
@media (max-width: 1050px) {
	.advertisement-container {
		flex-direction: column;
		height: 25vh;
	}

	.advertisement-image1-textbox {
		top: 10%;
		left: 5%;
	}

	.advertisement-image1-text1 {
		font-size: 1.5rem;
	}

	.advertisement-image1-subtext {
		font-size: 2rem;
	}

	.advertisement-image1-text2 {
		font-size: 1.25rem;
	}

	.advertisement-image2 img {
		width: 60%;
		border-radius: 12px;
	}
}

/* Small screens */
@media (max-width: 480px) {
	.advertisement-container {
		height: 20vh;
	}

	.advertisement-image1-textbox {
		top: 30%;
		left: 5%;
		gap: 0.5rem;
		width: 11.625rem;
	}

	.advertisement-image1-text1 {
		color: #fff;
		font-family: "DM Sans", sans-serif;
		font-size: 1.175rem;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}

	.advertisement-image1-subtext {
		color: #d6782b;
		font-family: "DM Sans", sans-serif;
		font-size: 1.175rem;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}

	.advertisement-image1-text2 {
		font-size: 1rem;
		display: none;
	}

	.advertisement-image2 img {
		display: none;
		width: 100%;
		position: relative;
		border-radius: 12px;
		margin-top: 1rem;
	}
}
