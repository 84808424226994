.forgot-password-container {
    margin-top: 7%;
    margin-bottom: 10%;
    background: #ffffff;
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    height:80%;
    max-width: 400px;
  }

  .forgot-password-container h2 {
    margin-bottom: 20px;
    font-size: 24px;
    text-align: center;
  }

  .forgot-password-container label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
  }

  .forgot-password-container input[type="email"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-sizing: border-box;
  }

  .forgot-password-container button {
    width: 100%;
    padding: 12px;
    background-color: #000000;
    color: #ffffff;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 10px;
  }

  .forgot-password-container button:hover {
    background-color: #000000;
  }

  .forgot-password-container p {
    text-align: center;
    margin-top: 20px;
  }

  .forgot-password-container a {
    color: #000000;
    text-decoration: none;
  }

  .forgot-password-container a:hover {
    text-decoration: underline;
  }

  .otp-section {
    text-align: center;
    margin-top: 20px;
  }

  .otp-inputs {
    display: flex;
    justify-content: center;
    gap: 12px;
  }

  .otp-inputs input {
    width: 50px;
    height: 50px;
    font-size: 24px;
    text-align: center;
    border: 2px solid #ccc;
    border-radius: 8px;
    outline: none;
    box-sizing: border-box;
    background-color: #ffffff;
    transition: all 0.3s ease;
  }

  .otp-inputs input:focus {
    border-color: #000000;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0);
    transform: scale(1.05);
  }
  .otp-buttons{
    margin-top: 30px;
  }

  .timer {
    text-align: center;
    margin-bottom: 20px;
    font-size: 14px;
    color: #b70000;
  }

  /* Responsive Design */
 
  @media (max-width: 600px) {
    .forgot-password-container {
      padding: 20px;
    }
  }
  @media (max-width: 400px) {
        .otp-inputs {
          gap: 2px;
        }
        .otp-inputs input {
          width: 35px;
          height: 35px;
          font-size: 16px;
        }
      .otp-buttons {
        display: flex;
        justify-content: center;
        margin-top: 10px;
      }
  
      .otp-buttons button {
        background-color: #000000;
        color: #ffffff;
        border: none;
        border-radius: 8px;
        padding: 12px 20px;
        font-size: 16px;
        cursor: pointer;
      }
  
      .timer {
        text-align: center;
        font-size: 14px;
        color: #555;
        margin-top: 10px;
      }
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    .otp-section {
        width: 100%;
        max-width: 400px;
        background: #ffffff;
        padding: 20px;
        border-radius: 12px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0);
      }
      .otp-inputs {
        display: flex;
        justify-content: center;
        gap: 8px;
      }
      .otp-inputs input {
        width: 50px;
        height: 50px;
        font-size: 24px;
        text-align: center;
        border: 2px solid #ccc;
        border-radius: 8px;
        background-color: #ffffff;
      }
      .otp-buttons {
        display: flex;
        justify-content: center;
        margin-top: 10px;
      }
      .otp-buttons button {
        background-color: #000000;
        color: #ffffff;
        border: none;
        border-radius: 8px;
        padding: 12px 20px;
        font-size: 16px;
        cursor: pointer;
      }
      .timer {
        text-align: center;
        font-size: 14px;
        color: #b70000;
        margin-top: 10px;
      }
    .forgot-password-container {
      padding: 30px;
    }
  }

  @media (min-width: 1025px) {
    .forgot-password-container {
      max-width: 500px;
    }
  }