.online_learning_container {
	width: 90%;
	flex-grow: 1;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1.2rem;
}

.online_learning_navbar_container {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.oln_leftPart {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 1.91rem;
}

.oln_leftPart-text-selected {
	color: #000;
	font-family: "Sora", sans-serif;
	font-size: 0.625rem;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	border-bottom: 1px solid black;
}

.oln_leftPart-text-selected:hover {
	cursor: pointer;
}

.oln_leftPart-text {
	color: rgba(16, 16, 16, 0.4);
	font-family: "Sora", sans-serif;
	font-size: 0.625rem;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.oln_leftPart-text:hover {
	cursor: pointer;
}


.onl_heading {
	width: 120%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: black;
	align-items: center;
	flex-shrink: 0;
	margin-left: -3%;
}

.onl_heading_text {
	color: #fff;
	font-family: "Sora", sans-serif;
	font-size: 0.7375rem;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	letter-spacing: 0.075rem;
	padding-top: 0.96rem;
	padding-bottom: 0.96rem;
}

@media (min-width: 800px) {
	.oln_leftPart {
		gap: 4.13rem;
	}

	.oln_leftPart-text-selected {
		font-size: 1.25rem;
	}

	.oln_leftPart-text {
		font-size: 1.25rem;
	}


	.onl_heading {
		margin-left: -3%;
	}

	.onl_heading_text {
		font-size: 1.45rem;
		letter-spacing: 0.08rem;
	}
}

@media (min-width: 1200px) {
	.onl_heading_text {
		font-size: 2.25rem;
		letter-spacing: 0.18rem;
	}
}
